@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@font-face {
  font-family: FertigoPro;
  src: url("./fonts/Fertigo_PRO.otf");
}

html,
body {
  height: 100%;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
}
.form-control:focus {
  box-shadow: none !important;
}

.fold1 {
  z-index: -1;
  .player-wrapper {
    position: absolute;
    width: 100%;
    video {
      object-fit: cover;
      width: 100vw;
      min-height: 100vh;
    }
  }
}
.lazyload-wrapper {
  padding: 0 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: unset;
  transition: none;
  box-shadow: none;
}
.fold1OverlayDiv {
  overflow: hidden;
  .languageButton {
    height: 40px;
    border-radius: 40px;
    background: #ffffff;
    border: 1px solid #23a2b5;
    font-size: 16px;
    line-height: 16px;
    padding: 0px 25px 6px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  .fold1ContentDiv {
    width: 100vw;
    min-height: 100vh;
    /*min-height: 1110px;*/
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 78px;
    flex-wrap: nowrap;

    .logoDiv {
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logoImage {
        width: 166px;
        height: 181px;
      }
    }

    .letsGoDiv,
    .logoDiv {
      padding-left: 220px;
      padding-right: 220px;
    }

    .svgContainerDiv {
      margin-bottom: -6px;

      svg {
        max-width: 100%;
        height: 100%;
        width: 100%;
      }
    }

    .letsGoDiv {
      width: 60%;
      margin-top: 0px;

      .letsGo {
        font-size: 50px;
        line-height: 70px;
        font-family: FertigoPro;
        color: white;
        font-weight: 400;
      }

      .letsGoQuestion,
      .letsGoAnswer {
        font-size: 20px;
        line-height: 26px;
        color: white;
        font-weight: 500;
      }

      .letsGoAnswer {
        font-weight: bold;
        margin-bottom: 25px;
      }

      .joinWaitlistButton {
        height: 80px;
        background: #23a2b5;
        border-radius: 60px;
        border-color: transparent;
        padding: 0 0 2px;
        font-size: 25px;
        font-weight: 500;
        min-width: 350px;
        color: white;
        line-height: 45px;
      }
    }
  }
}

.fold2 {
  padding-left: 50px;
  padding-right: 50px;
  display: flex;

  .firstColumn {
    .waveIcon {
      width: 86px;
      height: 60px;
      object-fit: contain;
    }
  }

  .secondColumn {
    margin-left: 25px;
    flex-grow: 1;
    .firstRow {
      display: flex;
      .theWorldsText {
        height: 60px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        font-size: 55px;
        line-height: 55px;
        font-family: FertigoPro;
      }
    }
    .secondRow {
      height: 70px;
      margin-top: 32px;
      p {
        font-size: 65px;
        line-height: 65px;
        margin: 0;
        font-family: FertigoPro;
        &::after {
          color: #23a2b5 !important;
        }
      }
    }
    .thirdRow {
      margin-top: 60px;
      .surfAbuDhabi {
        font-size: 64px;
        line-height: 68px;
        color: #d09871;
        font-family: FertigoPro;
      }
      .hudayriyatIsland {
        color: #23a2b5;
        margin-bottom: 0;
        font-size: 32px;
        line-height: 32px;
        border-radius: 40px 90px 60px 125px;
      }
      .fold2Text1Div {
        margin-top: 25px;
        margin-bottom: 30px;
      }
      .fold2Text1 {
        font-size: 28px;
        line-height: 42px;
      }
      .walkingSurfer {
        height: 1100px;
        margin-top: -80px;
        padding-left: 25px;
        z-index: -1;
        position: relative;
        opacity: 0.5;
      }
    }
    .fourthRow {
      .hudayriyatIslandDiv {
        width: 560px;
        height: 350px;
        margin-top: 34px;
        display: flex;
        flex-direction: column;
        position: relative;
        .hudayriyatIsland {
          width: 100%;
          height: 100%;
        }
        .spectacleImage {
          height: 230px;
          width: 260px;
          object-fit: contain;
          position: absolute;
          bottom: -57.5px;
          right: -130px;
        }
        .PinVectorImage {
          width: 35px;
          height: 75px;
          object-fit: contain;
          position: absolute;
          top: -17px;
          left: 60px;
        }
        .ThreeStripesImageTop {
          width: 75px;
          height: 41.5px;
          top: 56.5px;
          right: -28px;
          position: absolute;
        }
        .ThreeStripesImageBottom {
          width: 75px;
          height: 41.5px;
          left: -28px;
          bottom: 56.5px;
          position: absolute;
        }
      }
      .readMoreBtn {
        font-size: 24px;
        line-height: 24px;
        background: transparent;
        cursor: pointer;
        margin-bottom: 0;
        color: #23a2b5;
        .readMoreBtnArrow {
          margin-left: 2.5px;
          width: 21px;
          height: 21px;
          &.rotateArrow {
            transition: all 0.5s;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.foldSeparatorDiv {
  margin-top: -15px;
  padding: 0;
  opacity: 0.5;
}

.fold3 {
  // margin-top: 60px;
  background-color: white;
  border-bottom-left-radius: 200px;
  z-index: 1;
  position: relative;
  margin-left: -2px;
  width: calc(100% + 2px);
  &::before {
    position: absolute;
    content: "";
    background: url(https://d2rvxcax2co8em.cloudfront.net/bg-second-fold.webp);
    width: 100%;
    height: 100%;
    top: -5%;
    left: 0;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 0;
  }
  .fyw-col:focus-visible {
    outline: none !important;
  }
  .fyw-sec {
    text-align: center;
    margin: auto;
    padding: 0 0 150px;
    max-width: 94%;
    padding-top: 80px;
    position: relative;
  }
  .fyw-sec h2 {
    font-family: FertigoPro;
    font-size: 75px;
    color: #000;
    margin-bottom: 66px;
  }
  .fyw-sec .tab-pane ul li {
    list-style: none;
    font-size: 30px;
    font-family: "Poppins", sans-serif;
    color: #fff;
    text-align: left;

    // background-position: 0 15px;
    // padding-left: 50px;
    margin-bottom: 25px;
    // background-size: 40px;
    display: flex;
    align-items: flex-start;
    img {
      width: 31px;
      height: 4px;
      margin-top: 13px;
      object-fit: contain;
    }
    p {
      font-size: 20px;
      line-height: 28px;
      flex-basis: fit-content;
      padding-left: 5px;
    }
  }
  .fyw-sec .tab-pane ul li span.fyw-cont-sub {
    font-size: 24px;
  }
  .fyw-sec .fyw-cont {
    box-shadow: 0px 4px 65px 0px #00000026;
    padding: 20px 60px 70px;
    text-align: left;
    border-top: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 60px;
    padding-top: 80px;
    margin-top: -80px;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }
  p {
    font-size: 24px;
    line-height: 38px;
    color: #000000;
  }
  .fyw-sec .fyw-cont img {
    max-width: 35px;
    margin: 34px 0px;
  }
  .fyw-sec .desktop-img {
    position: relative;
  }
  .fyw-sec .desktop-img:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: 19%;
    background-size: 182px;
    top: 24%;
  }
  .fyw-sec #nav-surf .desktop-img:before {
    background: url(https://d2rvxcax2co8em.cloudfront.net/wave-icon-01.webp) no-repeat;
    background-size: 182px;
  }
  .fyw-sec #nav-learn .desktop-img:before {
    background: url(https://d2rvxcax2co8em.cloudfront.net/wave-icon-02.webp) no-repeat;
    background-size: 182px;
  }
  .fyw-sec #nav-exp .desktop-img:before {
    background: url(https://d2rvxcax2co8em.cloudfront.net/wave-icon-03.webp) no-repeat;
    background-size: 182px;
  }
  // .fyw-sec .tab-content a.desktop-img:hover ~ .fyw-cont {
  //   display: block;
  //   background-color: rgba(31, 161, 180, 0.8);
  //   margin: auto;
  //   padding: 30px;
  //   height: 100%;
  //   top: 0%;
  //   border-bottom-left-radius: 75px;
  //   border-bottom-right-radius: 40px;
  //   border-top-left-radius: 20px;
  //   border-top-right-radius: 60px;
  // }
  .fyw-sec .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: 0;
  }
  .fyw-sec .fyw-main-img {
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 60px;
    border-top-left-radius: 80px;
    border-top-right-radius: 40px;
  }
  .fyw-sec ul li.fyw-col .nav-link:focus-visible {
    outline: 0;
    box-shadow: none;
  }
}

.fold4 {
  margin-top: -200px;
  background: white;
  border-top-right-radius: 200px;
  .surf-wrapper {
    padding: 125px 0 0;
  }
  .surf-wrapper img {
    width: 100%;
    height: auto;
  }
  .main-wrap {
    position: relative;
    z-index: 0;
    width: 50%;
  }
  .content-wrap {
    border-radius: 100px 40px 85px 40px;
    height: 580px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    position: relative;
    padding: 0 55px;
    background: #fff;
    z-index: 2;
    align-items: center;
  }
  .flex-box {
    display: flex;
    position: relative;
    padding-bottom: 110px;
    justify-content: space-around;
    flex-direction: row;
  }
  .img-heading {
    padding-bottom: 25px;
    font: 36px/44px "Fertigo Pro";
    width: 460px;
  }
  .learn-more {
    color: #23a2b5;
    font: 24px/30px "Poppins";
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .learn-arrow {
    height: 35px;
    width: 38px;
    padding-left: 10px;
  }
  .para-sec {
    width: 40%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .para-sec p {
    width: 80%;
    font: 32px/52px "Poppins";
  }
  .skelly-img {
    width: 442px;
    height: 246px;
  }
  .modon-img {
    width: 460px;
  }
  .person-img {
    width: 357px;
    position: absolute;
    top: -105px;
    left: 15%;
  }
  .sun-img {
    position: absolute;
    top: -70px;
    right: -115px;
    z-index: 1;
  }
  .thumb-img {
    width: 86px;
    position: absolute;
    bottom: -30px;
    right: 12%;
  }
  .wave-img {
    width: 150px;
    position: absolute;
    top: 30px;
    left: -105px;
    z-index: 1;
  }
  .surf-board-img {
    width: 100px;
    position: absolute;
    bottom: -30px;
    right: 12%;
  }
  .snail-img {
    width: 60px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.fold5 {
  padding: 0;
  margin-top: -200px;
  .waves-wrapper {
    background: url("https://d2rvxcax2co8em.cloudfront.net/shutterstock_1168140622_1.webp");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    min-height: calc(100vh + 600px);
    display: flex;
    align-items: center;
    img {
      max-width: 100%;
      height: auto;
      display: block;
      border-radius: 25px;
    }
    .waves-content {
      width: 78%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: auto;
      .recerate-sec {
        display: flex;
        width: 55%;
        flex-direction: column;
        position: relative;
      }
      .recreate-head {
        font: 75px/90px FertigoPro;
        color: #fff;
        letter-spacing: -0.32px;
      }
      .surf-video {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        p {
          color: #fff;
          font: 24px/40px "Poppins";
          padding-top: 45px;
          letter-spacing: -0.32px;
        }
        .video-sec {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .btn-play {
            position: absolute;
            width: 100px;
          }
          .surf-logo {
            position: absolute;
            right: 10px;
            height: 118px;
            width: 108px;
            top: 20px;
          }
        }
      }
      .sub-wrap {
        width: 650px;
      }
    }
  }
}

.fold6 {
  padding: 0;
  margin-top: -1px;
  .footer-sec {
    // background-color: #23a2b5;
    margin-top: 0px;
    background: url(https://d2rvxcax2co8em.cloudfront.net/footer-01.webp);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    .foot-form-sec {
      // max-width: 50%;
      margin-left: 16%;
      padding-top: 32%;
      max-width: 36%;
      padding-bottom: 10%;
      .foot-form-head {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 86px;
          height: 70px;
          background: url(https://d2rvxcax2co8em.cloudfront.net/ic-rt.svg) no-repeat;
          margin-left: -22%;
          margin-top: 45px;
          background-position: left center;
        }
      }
      .form-inline {
        max-width: 95%;
        .form-group {
          margin-top: 45px;
          &.form-group-select {
            // input{color: #ffffff; background: transparent; border: 0;
            // &::placeholder{color: #fff;}
            // }
            margin-top: 57px;
            .css-1dimb5e-singleValue {
              color: #ffffff;
              background: transparent;
              font-weight: 500;
            }
            .css-t3ipsp-control {
              color: #ffffff;
              background: transparent;
            }
            .css-1u9des2-indicatorSeparator {
              display: none;
            }
            .css-inmdiq5-menu {
              background: transparent;
              color: #ffffff;
            }
            .css-t3ipsp-control {
              border: 0;
              box-shadow: none;
              color: #000000;
            }
            .css-1fdsijx-ValueContainer {
              color: #ffffff;
            }
            .css-1xc3v61-indicatorContainer .css-tj5bde-Svg {
              fill: #ffffff;
            }
            .css-1xc3v6c-indicatorContainer .css-tj5bde-Svg {
              fill: #000000;
            }
            .css-15lsz6c-indicatorContainer,
            .css-166bipr-Input {
              color: #ffffff;
            }
            // .css-1fdsijx-ValueContainer{color: #ffffff; background: transparent;}
            .css-13cymwt-control,
            .css-t3ipsp-control {
              background-color: transparent;
              position: relative;
              border: 0;
              border-bottom: 2px solid #fff;
              border-radius: 0;
              color: #ffffff;
              font-size: 24px;
              font-family: "Poppins", sans-serif;

              // background-position: center right 30px;
              &::before {
                content: "";
                width: 11px;
                height: 11px;
                position: relative;
                top: 25px;
                background-color: #fff;
                display: block;
                border-radius: 50px;
              }
              &::after {
                content: "";
                width: 11px;
                height: 11px;
                position: relative;
                top: 25px;

                background-color: #fff;
                display: block;
                border-radius: 50px;
              }
              .css-1jqq78o-placeholder {
                color: #ffffff;
                font-size: 18px;
                font-family: "Poppins", sans-serif;
                opacity: 0.3;
                font-weight: 500;
              }
            }
          }
          &.form-email input {
            font-weight: 500;
            font-size: 26px;
          }
          select {
            background-color: transparent;

            background-position: 95%;
            border: 0;
            border-bottom: 2px solid #fff;
            border-radius: 0;
            padding-bottom: 15px;
            color: #fff;
            width: 100%;
            padding-left: 30px;
            font-size: 24px;
            font-family: "Poppins", sans-serif;
            outline: 0;
            box-shadow: none;
            &:focus-visible {
              outline: 0;
            }
          }
          option {
            background-color: transparent;
            color: #000;
            padding: 10px;
            font-size: 16px;
          }
        }
        .radio-field {
          padding-left: 30px;
          h3 {
            padding-bottom: 25px;
            color: #fff;
            font-size: 24px;
            font-family: "Poppins", sans-serif;
            span {
              font-size: 16px;
              font-family: "Poppins", sans-serif;
              font-style: italic;
            }
          }
          label {
            font-size: 24px;
            margin-right: 20px;
            border-top-right-radius: 20px !important;
            border-bottom-right-radius: 20px !important;
            font-family: "Poppins", sans-serif;
            border: 0;
            border-radius: 20px;
            background: #fff;
            color: #000;
            padding: 15px 35px;
            color: #23a2b5;
            font-weight: 600;
          }
          .btn-group {
            display: flex;
            flex-wrap: wrap;
          }
        }
        .btn-check {
          &:checked {
            & + .btn {
              background: #d09871 !important;
              color: #fff;
            }
          }
        }
        button {
          background-color: #23a2b5;
          border-radius: 60px;
          width: 100%;
          box-shadow: 0px 10px 15px 0px #00000033;
          color: rgba(255, 255, 255, 1);
          // padding: 15px 20px;
          height: 80px;
          margin-top: 40px;
          font-size: 20px;
          transition: all 0.5s;
          &.disabled {
            color: rgba(255, 255, 255, 0.5);
            pointer-events: none;
            border-color: #23a2b5;
          }
          &.buttonLoading {
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }
      }
      label {
        color: #fff;
      }
      input {
        background-color: transparent;
        border: 0;
        font-size: 22px;
        padding-left: 20px;
        border-bottom: 2px solid #fff;
        color: #fff;
        border-radius: 0;
        padding-bottom: 15px;
        font-weight: 500;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-background-clip: text;
          -webkit-text-fill-color: #fff;
          transition: none;
          box-shadow: none;
          transition: background-color 0s 600000s, color 0s 600000s;
        }
        &::placeholder {
          color: #fff;
          font-size: 24px;
          font-family: "Poppins", sans-serif;
          opacity: 0.3;
        }
        & + span {
          display: flex;
          justify-content: space-between;
          &::before {
            content: "";
            position: relative;
            width: 11px;
            bottom: 6.5px;
            height: 11px;
            background-color: #fff;
            display: block;
            border-radius: 50px;
          }
          &::after {
            content: "";
            width: 11px;
            bottom: 6.5px;
            height: 11px;
            position: relative;
            background-color: #fff;
            display: block;
            border-radius: 50px;
          }
        }
        .form-control {
          &:focus {
            box-shadow: none;
          }
        }
      }
      select {
        & + span {
          display: flex;
          justify-content: space-between;
          position: relative;
          &::before {
            content: "";
            width: 15px;
            position: absolute;
            bottom: -6px;
            height: 15px;
            background-color: #fff;
            display: block;
            border-radius: 50px;
            left: 0;
          }
          &::after {
            content: "";
            width: 15px;
            position: absolute;
            bottom: -6px;
            height: 15px;
            background-color: #fff;
            display: block;
            border-radius: 50px;

            right: 0;
          }
        }
      }
      h2 {
        font-family: FertigoPro;
        font-size: 75px;
        color: #fff;
        margin-bottom: 25px;
        text-transform: capitalize;
        letter-spacing: -2px;
        span {
          display: block;
        }
      }
      P {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 400;
        line-height: 42px;
        text-align: left;
        color: #fff;
        margin-bottom: 50px;
      }
    }
    .foot-bt-sec {
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 0px;
      p {
        &.p1 {
          color: #fff;
          font-size: 24px;
          line-height: 36px;
          font-family: "Poppins", sans-serif;
          margin: 0;
          padding: 50px 0px 0px;
        }
        &.p2 {
          color: #fff;
          font-size: 14px;
          line-height: 21px;
          font-family: "Poppins", sans-serif;
          letter-spacing: 15px;
          padding: 60px 0px 10px;
          text-transform: uppercase;
          margin: 0;
        }
        &.p3 {
          color: #fff;
          font-size: 12px;
          line-height: 16px;
          font-family: "Poppins", sans-serif;
          margin: 0;
          letter-spacing: 2px;
        }
      }
    }
    ul {
      &.foot-menu {
        padding: 0;
        padding-bottom: 50px;
      }
      li {
        list-style: none;
        display: inline-block;
        margin: 0 10px;
        font-family: "Poppins", sans-serif;
        a {
          border-right: 1px solid #fff;
          color: #fff;
          font-size: 20px;
          text-decoration: none;
          padding-right: 20px;
        }
        &:last-child {
          a {
            border-right: none;
            padding-right: 0px;
          }
        }
      }
    }
    .foot-social {
      // padding: 50px 0px;
      margin: 0;
      li {
        list-style: none;
        display: inline-block;
        margin: 0;
        a {
          color: #fff;
          font-size: 27px;
          margin: 0px 15px;
          border: none;
          padding: 0;
          line-height: 30px;
        }
      }
    }
  }
}

.fold7 {
  background: url(https://d2rvxcax2co8em.cloudfront.net/SurfBoardBg.svg);
  height: 400px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  .asFeaturedInText {
    font-size: 40px;
    line-height: 48px;
    font-family: FertigoPro;
    text-align: center;
    margin-bottom: 35px;
    margin-top: -35px;
  }
  .marquee {
    &.staticMarquee {
      display: flex;
      justify-content: center;
    }
    img {
      width: 235px;
      //height: 60px;
      margin-left: 50px;
      margin-right: 50px;
      cursor: pointer;
      border-radius: 10px;
    }
    .child {
      display: flex;
      align-items: center;
    }
  }
}

.fold8 {
  margin-top: 45px;
  .lat-rel-sec {
    position: relative;
    z-index: 1;
    max-width: 87%;
    margin: auto;
    .carousel {
      max-width: 95%;
      margin-left: auto;
    }
    .container {
      position: relative;
    }
    h2 {
      text-align: center;
      font-family: FertigoPro;
      font-size: 75px;
      margin-bottom: 50px;
      letter-spacing: -2px;
    }
    .carousel-item {
      width: 100%;
    }
    .carousel-inner {
      overflow: hidden;
      padding-bottom: 85px;
    }
    .carousel-img {
      width: 60%;
      position: absolute;
    }
    .carousel-caption {
      box-shadow: 0px 4px 65px 0px #00000026;
      border-radius: 30px;
      text-align: left;
      background: #fff;
      right: 0% !important;
      left: 34% !important;
      width: 60%;
      padding: 50px 70px;
      margin-top: 8%;
      position: relative !important;
      min-height: 500px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      h3 {
        color: #000;
        margin: 0;
        font-size: 30px;
        line-height: 45px;
        padding-bottom: 25px;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
      }
      p {
        margin: 0;
        padding-bottom: 20px;
        color: #000;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        line-height: 32px;
      }
    }
    a {
      font-size: 16px;
      line-height: 16px;
      text-transform: capitalize;
      color: #23a2b5;
      text-decoration: none;
      font-family: "Poppins", sans-serif;
      img {
        padding-left: 10px;
      }
      &.desktopReadMore {
        display: block;
      }
      &.mobileReadMore {
        display: none;
      }
    }

    img {
      &.slider-img {
        height: 500px;
        width: 100%;
        border-radius: 60px;
        object-fit: cover;
      }
    }
    .carousel-control-next,
    .carousel-control-prev {
      top: 50% !important;
      opacity: 1 !important;
      width: 6%;
    }
    .carousel-control-prev {
      background: url(https://d2rvxcax2co8em.cloudfront.net/left-arrow-icon.svg) no-repeat center top;
      left: -2%;
    }
    .carousel-control-next {
      background: url(https://d2rvxcax2co8em.cloudfront.net/right-arrow-icon.svg) no-repeat center top;
      right: -2%;
    }
    .carousel-indicators {
      display: none;
    }
  }
}

.fold9 {
  border-top-right-radius: 200px;
  background-color: #fff;
  margin-top: -200px;

  .build-modon {
    max-width: 53%;
    margin: 85px auto;

    span.modon-border {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #e6e6e6;
      margin: 50px 0px;

      &::before {
        content: "";
        width: 15px;
        position: relative;
        bottom: -9px;
        height: 15px;
        right: 15px;
        background-color: #e6e6e6;
        display: block;
        border-radius: 50px;
      }

      &::after {
        content: "";
        width: 15px;
        position: relative;
        bottom: -9px;
        height: 15px;
        background-color: #e6e6e6;
        display: block;
        border-radius: 50px;
        left: 15px;
      }

      &.mob-show {
        display: none;
      }
    }

    .logo-sec h5 {
      font-size: 36px;
      line-height: 43px;
      color: #000000;
      font-family: FertigoPro;
      margin-bottom: 30px;
    }

    .cont-sec {
      display: flex;

      .cont-01 {
        width: 39%;

        p {
          font-size: 18px;
          line-height: 42px;
        }
      }

      .cont-02 {
        width: 61%;

        p {
          font-size: 28px;
          line-height: 52px;
          color: #000000;
          letter-spacing: -1px;
          margin-bottom: 30px;
        }

        a {
          font-size: 24px;
          text-transform: capitalize;
          color: #23a2b5;
          text-decoration: none;
          font-family: "Poppins", sans-serif;

          img {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
/*LWF*/
.fold02 {
  padding: 0;
}
.lwf-banner {
  background: url(https://d2rvxcax2co8em.cloudfront.net/lwf-banner.webp);
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 108vh;
  position: relative;
  z-index: 0;
  &::before {
    background: url(https://d2rvxcax2co8em.cloudfront.net/lwf-shadow.webp);
    background-size: contain;
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    width: 100%;
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    z-index: 1;
  }
  /*&::after{
    
    width:100%;
    content:"";
    bottom:0;
    left:0;height:100vh; 
    z-index: 1;
    position: absolute;
  }*/
  .lwf-cont {
    margin: 0 auto 0;
    max-width: 950px;
    position: relative;
    z-index: 2;
    text-align: center;
    h2 {
      color: #23a2b5;
      font: 46px/90px FertigoPro;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
    h3 {
      color: #000;
      font: 72px/110px FertigoPro;
      font-weight: 400;
      margin: 0;
      padding: 0 0 30px;
      position: relative;
      overflow: hidden;
    }
    p {
      color: #000;
      font: 28px/42px "Poppins", sans-serif;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
  }
}
/*Model*/
.reg-model-popup {
  .modal-dialog {
    max-width: 880px;
    .modal-form {
      padding: 50px;
      position: relative;
      a.modal-close {
        position: absolute;
        top: -5%;
        right: -9%;
        cursor: pointer;
      }
      p {
        font-family: FertigoPro;
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        text-align: left;
        color: #000000;
      }
      .form-inline {
        .form-group {
          margin-top: 35px;
          &.form-email input {
            font-weight: 500;
            color: #23a2b5;
            font-size: 26px;
            &::placeholder {
              // font-weight: 500;
              // color: #23a2b5;
              // font-size: 26px;
            }
          }

          &.form-group-select {
            // input{color: #ffffff; background: transparent; border: 0;
            // &::placeholder{color: #fff;}
            // }
            .css-1dimb5e-singleValue {
              color: #23a2b5;
              background: transparent;
              font-weight: 500;
            }
            .css-t3ipsp-control {
              color: #000;
              background: transparent;
            }
            .css-1u9des2-indicatorSeparator {
              display: none;
            }
            .css-inmdiq5-menu {
              background: transparent;
              color: #000;
            }
            .css-t3ipsp-control {
              border: 0;
              box-shadow: none;
              color: #000000;
            }
            .css-1fdsijx-ValueContainer {
              color: #000;
            }
            .css-1xc3v61-indicatorContainer .css-tj5bde-Svg {
              fill: #c5c2c2;
            }
            .css-1xc3v6c-indicatorContainer .css-tj5bde-Svg {
              fill: #c5c2c2;
            }
            .css-15lsz6c-indicatorContainer,
            .css-166bipr-Input {
              color: #000;
            }
            // .css-1fdsijx-ValueContainer{color: #ffffff; background: transparent;}
            .css-13cymwt-control,
            .css-t3ipsp-control {
              background-color: transparent;
              position: relative;
              border: 0;
              border-bottom: 2px solid #c5c2c2;
              border-radius: 0;
              color: #000;
              font-size: 24px;
              font-family: "Poppins", sans-serif;

              // background-position: center right 30px;
              &::before {
                content: "";
                width: 15px;
                position: relative;
                top: 25px;
                height: 15px;
                background-color: #c5c2c2;
                display: block;
                border-radius: 50px;
              }
              &::after {
                content: "";
                width: 15px;
                position: relative;
                top: 25px;
                height: 15px;
                background-color: #c5c2c2;
                display: block;
                border-radius: 50px;
              }
              .css-1jqq78o-placeholder {
                color: #000;
                font-size: 24px;
                font-weight: 500;
                font-family: "Poppins", sans-serif;
              }
            }
          }

          input {
            background-color: transparent;
            border: 0;
            font-size: 26px;
            padding-left: 30px;
            border-bottom: 2px solid #c5c2c2;

            border-radius: 0;
            padding-bottom: 15px;
            font-weight: 500;
            color: #23a2b5;
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              -webkit-text-fill-color: #23a2b5;
              transition: background-color 0s 600000s, color 0s 600000s;
            }
            &::placeholder {
              color: #000000;
              font-size: 22px;
              font-weight: 500;
              font-family: "Poppins", sans-serif;
            }
            & + span {
              display: flex;
              justify-content: space-between;
              &::before {
                content: "";
                width: 15px;
                position: relative;
                bottom: 9px;
                height: 15px;
                background-color: #c5c2c2;
                display: block;
                border-radius: 50px;
              }
              &::after {
                content: "";
                width: 15px;
                position: relative;
                bottom: 9px;
                height: 15px;
                background-color: #c5c2c2;
                display: block;
                border-radius: 50px;
              }
            }
            .form-control {
              &:focus {
                box-shadow: none;
              }
            }
          }
          select {
            & + span {
              display: flex;
              justify-content: space-between;
              position: relative;
              &::before {
                content: "";
                width: 15px;
                position: absolute;
                bottom: -6px;
                height: 15px;
                background-color: #c5c2c2;
                display: block;
                border-radius: 50px;
                left: 0;
              }
              &::after {
                content: "";
                width: 15px;
                position: absolute;
                bottom: -6px;
                height: 15px;
                background-color: #c5c2c2;
                display: block;
                border-radius: 50px;
                right: 0;
              }
            }
          }
          select {
            background-color: transparent;
            background-image: url(https://d2rvxcax2co8em.cloudfront.net/modal-down-arrow.webp);
            background-position: 95%;
            border: 0;
            border-bottom: 2px solid #c5c2c2;
            border-radius: 0;
            padding-bottom: 15px;
            color: #000000;
            width: 100%;
            padding-left: 30px;
            font-size: 22px;
            font-family: "Poppins", sans-serif;
            outline: 0;
            box-shadow: none;
            &:focus-visible {
              outline: 0;
            }
          }
          option {
            background-color: transparent;
            color: #000;
            padding: 10px;
            font-size: 16px;
          }
        }
        .btn-check {
          &:checked {
            & + .btn {
              background: #d09871 !important;
              color: #fff;
            }
          }
        }
        button {
          background-color: #23a2b5;
          border-radius: 60px;
          width: 100%;
          color: #ffffff;
          // padding: 15px 20px;
          height: 80px;
          margin-top: 40px;
          font-size: 20px;
          font-weight: 500;
          &.disabled {
            color: rgba(255, 255, 255, 0.5);
            pointer-events: none;
            border-color: #23a2b5;
          }
        }
      }
      .sec-modal {
        text-align: center;
        h3 {
          font-size: 44px;
          line-height: 53px;
          color: #23a2b5;
          font-family: FertigoPro;
          margin: 30px 0px;
        }
        p {
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          font-family: "Poppins", sans-serif;
          color: #000000;
        }
      }
    }
  }
  .modal-content {
    border-radius: 98px 56px 115px 37px;
  }
}
.text-transition {
  justify-content: center;
}
.skelly-ic {
  width: 150px;
}
.contactUsSuccessSwal {
  .swal2-popup {
    width: 768px;
    padding: 80px 25px 140px;
    border-radius: 100px 60px 120px 40px;
  }
  .swal2-title {
    font-family: FertigoPro;
    color: #23a2b5;
    font-size: 44px;
    line-height: 52px;
    padding: 0;
    margin-top: 34px;
  }
  .swal2-html-container {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    margin-top: 34px;
  }
  .swal2-image {
    margin-top: 0;
    margin-bottom: 0;
  }
  .swal2-close {
    position: absolute;
    width: 51px;
    height: 51px;
    right: -59px;
  }
}
@media screen and (min-width: 1023px) {
  .fold3 {
    .fyw-sec {
      // max-width: 990px;
    }
    nav {
      display: none;
    }
    .fyw-sec .tab-content > .tab-pane {
      // display: inline-block !important;
      width: 27%;
      margin-right: 8%;
      position: relative;
      display: flex;
      flex-flow: column;
    }
    .fyw-sec .tab-content > .tab-pane:first-child {
      margin-left: 5%;
    }
    .fyw-sec .tab-content > .tab-pane:last-child {
      margin-right: 0;
    }
    .fyw-sec .fade:not(.show) {
      opacity: 1;
    }
    .fyw-sec img {
      width: 100%;
    }
    // .fyw-sec a.desktop-img::after {
    //   position: absolute;
    //   border: 0;
    //   border-radius: 0;
    //   top: 0;
    //   right: 0;
    //   left: 0;
    //   bottom: 0;
    //   opacity: 0.1;
    //   z-index: 1;
    //   content: "";
    //   border-bottom-left-radius: 75px;
    //   border-bottom-right-radius: 40px;
    //   border-top-left-radius: 20px;
    //   border-top-right-radius: 60px;
    //   background: linear-gradient(180deg, rgba(255, 165, 31, 1) 0%, rgba(156, 189, 127, 1) 45%, rgba(36, 213, 239, 1) 100%);
    // }
    .fyw-sec span.fyw-sub-img img {
      width: auto;
    }
    .fyw-sec span.fyw-sub-img {
      position: absolute;
      bottom: -20%;
      width: 100%;
      height: auto;
      z-index: 1;
      left: 0;
      right: 0;
    }
    .fyw-sec .tab-content {
      display: flex;
      justify-content: space-between;
    }

    // .fyw-sec .fyw-cont {
    //   display: none;
    // }
    // .fyw-sec .tab-content a.desktop-img {
    //   display: block;
    //   width: 100%;
    // }
    // .fyw-sec .tab-content a.desktop-img:hover ~ .fyw-cont {
    //   display: block;
    //   position: absolute;
    //   width: 100%;
    //   top: 0;
    //   padding-top: 70px;
    // }
  }
}

@media screen and (max-width: 1921px) {
  .fold3 {
    .fyw-sec {
      margin: 0 auto 30px;
    }
    .fyw-sec .tab-pane ul li {
      font-size: 20px;
      margin-bottom: 15px;
      background-position: 0 11px;
    }
    .fyw-sec .tab-pane ul li span.fyw-cont-sub {
      font-size: 16px;
    }
    // .fyw-sec .tab-content a.desktop-img:hover ~ .fyw-cont {
    //   padding: 40px;
    //   padding-bottom: 75px;
    //   padding-top: 30px;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: space-evenly;
    // }
    // .fyw-sec span.fyw-sub-img img {
    //   max-width: 150px;
    //   &:focus-visible {
    //     outline: none !important;
    //   }
    // }
    // .fyw-sec span.fyw-sub-img {
    //   bottom: -15%;
    // }
  }
  .fold5 {
    .waves-wrapper {
      .waves-content {
        .recreate-head {
          font-size: 68px;
          line-height: 75px;
        }
      }
    }
  }
  .fold6 {
    .footer-sec {
      .foot-form-sec {
        // max-width: 65%;
        // padding-top: 30%;
        // h2 {
        //   font-size: 50px;
        //   padding-top: 15%;
        // }
        .form-inline {
          .form-group {
            // margin-bottom: 50px;
            &.radio-field {
              h3 {
                span {
                  font-size: 14px;
                }
              }
            }
            select {
              font-size: 18px;
            }
          }
          .radio-field {
            label {
              margin-right: 15px;
              padding: 10px 20px;
              margin-top: 10px;
            }
            h3,
            label {
              font-size: 18px;
            }
          }
          // button {
          //   font-size: 24px;
          //   padding: 15px 20px;
          // }
        }
        input {
          &::placeholder {
            font-size: 18px;
          }
          .form-inline {
            .radio-field {
              h3,
              label {
                font-size: 18px;
              }
            }
          }
        }
      }
      ul {
        &.foot {
          li {
            a {
              font-size: 16px;
            }
          }
        }
      }
      // .foot-social {
      //   padding: 30px 0px;
      // }
      .foot-bt-sec {
        p {
          &.p1 {
            padding: 30px 0px 0px;
            font-size: 18px;
          }
          &.p2 {
            font-size: 12px;
            letter-spacing: 10px;
          }
          // &.p3 {
          //   font-size: 14px;
          //   letter-spacing: 2px;
          //   padding: 50px 0px 50px;
          // }
        }
      }
    }
  }
  .fold8 {
    .lat-rel-sec {
      // .carousel-img {
      //   width: 55%;
      // }
      // h2 {
      //   font-size: 50px;
      // }
      .carousel-caption {
        // p {
        //   font-size: 16px;
        //   line-height: 26px;
        //   padding-bottom: 15px;
        // }
        // h3 {
        //   font-size: 24px;
        //   line-height: 36px;
        //   padding-bottom: 15px;
        // }
        // a {
        //   font-size: 18px;
        // }
      }
      // .carousel-control-prev {
      //   background-size: 60px;
      //   left: -3%;
      // }
      // .carousel-control-next {
      //   background-size: 60px;
      //   right: -4%;
      // }
    }
  }
}
@media (max-width: 1919px) {
  .fold3 {
    // p{font-size: 18px;line-height: 30px;}
    .fyw-sec {
      .tab-content > .tab-pane {
        width: 28%;
        margin-right: 5%;
      }
      .tab-content > .tab-pane:first-child {
        margin-left: 3%;
      }
      #nav-surf .desktop-img:before {
        background-size: 130px;
        top: 28%;
        right: 16%;
      }
      #nav-learn .desktop-img:before {
        background-size: 130px;
        top: 28%;
        right: 16%;
      }
      #nav-exp .desktop-img:before {
        background-size: 130px;
        top: 28%;
        right: 16%;
      }
      .fyw-cont {
        padding: 75px 40px 40px;
      }
    }
  }
  .fold9 {
    .build-modon {
      max-width: 60%;
      .cont-sec {
        .cont-01 {
          width: 40%;
          p {
            font-size: 18px;
            line-height: 42px;
          }
        }
        .cont-02 {
          width: 60%;
          p {
            font-size: 22px;
            line-height: 40px;
            margin-bottom: 20px;
          }
          a {
            font-size: 18px;
          }
        }
      }
    }
  }
  .fold1OverlayDiv {
    .fold1ContentDiv {
      .svgContainerDiv {
        margin-bottom: -45px;
      }
    }
  }
  .lwf-banner {
    height: 130vh;
  }
  .fold5 {
    .waves-wrapper {
      .waves-content {
        padding: 150px 16px;

        .recreate-head {
          font-size: 68px;
          line-height: 75px;
        }
      }
    }
  }
  .fold8 {
    .lat-rel-sec {
      img.slider-img {
        height: 410px;
      }
      .carousel-caption {
        min-height: inherit;
        justify-content: space-between;
      }
    }
  }
}
/*@media (max-width: 1819px) {
    .fold5 {
        .waves-wrapper {
            .waves-content {
                width: 85%;            
            }
        }
    }
}*/

@media (max-width: 1599px) {
  .fold1OverlayDiv {
    .fold1ContentDiv {
      .svgContainerDiv {
        margin-bottom: -2px;
      }
    }
  }
  .fold1OverlayDiv {
    .fold1ContentDiv {
      padding-top: 30px;
    }
  }
  // .fold1OverlayDiv {
  //   .fold1ContentDiv {
  //     .letsGoDiv {
  //       .letsGo {
  //         font-size: 50px;
  //         line-height: 70px;
  //       }
  //     }
  //   }
  // }
  // .fold1OverlayDiv {
  //   .fold1ContentDiv {
  //     .letsGoDiv {
  //       .letsGoQuestion,
  //       .letsGoAnswer {
  //         font-size: 20px;
  //         line-height: 24px;
  //       }
  //     }
  //   }
  // }
  .fold1OverlayDiv {
    .fold1ContentDiv {
      .letsGoDiv {
        .joinWaitlistButton {
          height: 60px;
          font-size: 24px;
          min-width: 300px;
          line-height: 40px;
        }
      }
    }
  }
  .lwf-banner {
    .lwf-cont {
      h3 {
        font-size: 60px;
        line-height: 90px;
      }
    }
  }
  .fold1OverlayDiv {
    .fold1ContentDiv {
      .letsGoDiv,
      .logoDiv {
        padding-left: 100px;
        padding-right: 100px;
      }
    }
  }
  .fold5 {
    .waves-wrapper {
      .waves-content {
        width: 83%;
        .recerate-sec {
          .recreate-head {
            font-size: 58px;
            line-height: 70px;
          }
        }

        .sub-wrap {
          width: 650px;
        }
      }
    }
  }
  .fold3 .fyw-sec h2 {
    font-size: 60px;
  }
  .fold8 .lat-rel-sec h2 {
    font-size: 60px;
  }
}
@media (max-width: 1380px) {
  .fold1 .player-wrapper {
    width: 100%;
    overflow: hidden;
  }
  .fold1OverlayDiv {
    width: 100%;
  }
  .fold3 {
    .fyw-sec {
      .fyw-cont {
        padding: 75px 45px 40px;
      }
      #nav-surf .desktop-img:before,
      #nav-learn .desktop-img:before,
      #nav-exp .desktop-img:before {
        background-size: 100px;
        top: 30%;
      }
    }
  }
  .fold6 {
    .footer-sec {
      .foot-form-sec {
        h2 {
          font-size: 45px;
          margin-bottom: 20px;
        }
        p {
          font-size: 18px;
          line-height: 32px;
        }
        form {
          button {
            font-size: 20px;
          }
        }
      }
    }
  }
  .fold8 {
    .lat-rel-sec {
      .carousel-control-prev,
      .carousel-control-next {
        background-size: 50px;
      }
    }
  }
  .fold5 {
    .waves-wrapper {
      .waves-content {
        width: 90%;
        .recerate-sec {
          width: 70%;
          padding-bottom: 20px;
        }
        .surf-video {
          .sub-wrap {
            width: 550px;
            .video-sec {
              .btn-play {
                width: 95px;
              }
              .surf-logo {
                height: 100px;
                width: 100px;
              }
            }
          }
        }
      }
    }
  }
  .lwf-banner {
    height: 185vh;
  }
}
@media (max-width: 1359px) {
  .lwf-banner {
    height: 130vh;
  }
}
@media (max-width: 1199px) {
  .fold3 {
    .fyw-sec {
      #nav-surf,
      #nav-learn,
      #nav-exp {
        .desktop-img {
          &::before {
            background-size: 70px;
          }
        }
      }
      .fyw-cont {
        padding: 75px 20px 20px;
        img {
          margin: 30px 0px 20px;
        }
      }
    }
  }
  .fold9 {
    .build-modon {
      max-width: 100%;
      padding: 0px 16px;
    }
  }
  .fold5 {
    .waves-wrapper {
      .waves-content {
        width: 95%;
        .recerate-sec {
          .recreate-head {
            font-size: 55px;
            line-height: 70px;
          }
        }
        .surf-video {
          p {
            font-size: 20px;
            line-height: 34px;
            padding-top: 35px;
          }
          .sub-wrap {
            width: 550px;
            .video-sec {
              .btn-play {
                width: 90px;
              }
              .surf-logo {
                height: 85px;
                width: 85px;
              }
            }
          }
        }
      }
    }
  }
  .fold6 {
    .footer-sec .foot-form-sec {
      padding-top: 40%;
      .foot-form-head::before {
        margin-left: -30%;
        margin-top: 35px;
      }
    }
  }
  .fold8 .lat-rel-sec {
    max-width: 98%;
    .carousel-inner {
      padding-bottom: 30px;
    }
    .carousel-caption {
      padding: 30px;
    }
  }
  .fold6 .footer-sec .foot-form-sec form .form-group.form-group-select .css-13cymwt-control {
    &::after {
      top: 30px;
    }
    &::before {
      top: 30px;
    }
  }
}

@media (max-width: 1023px) {
  /*Modal*/
  .reg-model-popup .modal-dialog {
    max-width: 80%;
    .modal-form {
      padding: 30px;
      a.modal-close {
        top: -10%;
        right: -10%;
      }
    }
  }
  .fold4 {
    .person-img {
      width: 225px;
      top: -105px;
      left: 15%;
    }
    .flex-box {
      padding-bottom: 0px;
    }
    .para-sec p {
      font-size: 22px;
      line-height: 28px;
      width: 100%;
    }
    .content-wrap {
      margin-bottom: 30px;
      height: 280px;
      padding: 0 60px;
      border-radius: 70px 30px 60px 30px;
    }
    .img-heading {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 20px;
      width: 207px;
    }
    .skelly-img {
      width: 207px;
      height: 124px;
    }
    .modon-img {
      width: 225px;
    }
    .sun-img {
      width: 130px;
      top: -50px;
      right: -65px;
    }
    .thumb-img {
      width: 58px;
      bottom: -20px;
      right: 18%;
    }
    .wave-img {
      width: 90px;
      left: -50px;
    }
    .surf-board-img {
      width: 59px;
      bottom: -20px;
      right: 18%;
    }
    .snail-img {
      width: 50px;
      right: 0;
    }
  }

  .fold3 {
    padding: 0 16px;
    border-bottom-left-radius: 40px;
    /*Find Your Wave*/
    .fyw-sec {
      margin: 0px;
      max-width: 100%;
      margin-bottom: 60px;
    }

    .fyw-sec .nav-tabs .nav-link {
      padding: 0;
      display: flex;
      justify-content: space-between;
      border: 0;
    }

    .fyw-sec nav {
      display: block;
    }

    .fyw-sec ul.nav {
      display: flex;
      justify-content: space-evenly;
    }

    .fyw-sec .tab-content {
      display: flex;
      justify-content: center;
    }

    .fyw-sec .tab-content > .tab-pane {
      position: relative;
      width: 100%;
      margin-right: 0%;

      &:first-child {
        margin-left: 0%;
      }
    }

    .fyw-sec .tab-content a.desktop-img .fyw-sub-img {
      display: none;
    }

    .fyw-sec {
      .desktop-img img {
        width: 100%;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 44px;
        border-top-left-radius: 60px;
        border-top-right-radius: 30px;
      }

      .fyw-cont {
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 44px;
        border-top-left-radius: 60px;
        border-top-right-radius: 30px;
      }
    }

    .fyw-sec .desktop-img:before {
      background: none !important;
    }
    // .fyw-sec .fyw-cont {
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   background-color: rgba(31, 161, 180, 0.85);
    //   margin: auto;
    //   padding: 30px;
    //   height: 100%;
    //   width: 100%;
    //   top: 0%;
    //   border-radius: 30px;
    // }
    // .fyw-sec .fyw-main-img {
    //   border-radius: 30px;
    // }
    .fyw-sec .tab-content a.desktop-img {
      position: relative;
    }

    .fyw-sec ul li.fyw-col a.nav-link img {
      width: 100%;
      filter: grayscale(100%);
    }

    .fyw-sec ul li.fyw-col a.nav-link.active img {
      width: 100%;
      filter: unset;
    }

    .fyw-sec .nav-tabs .nav-link.active {
      border: 0;
    }

    .fyw-sec .nav {
      border: 0;
    }

    .fyw-sec span.fyw-sub-img img {
      max-width: 100px;
    }
  }

  .fold6 {
    .footer-sec {
      .foot-form-sec {
        max-width: 45%;
        padding-top: 60%;
      }
    }
  }
  .fold8 .lat-rel-sec {
    max-width: 100%;
    .carousel-control-next {
      background-size: 40px;
      right: -1%;
    }
    .carousel-control-prev {
      background-size: 40px;
      left: -1%;
    }
  }
  .fold5 {
    .waves-wrapper {
      .waves-content {
        width: 100%;
        .sub-wrap {
          width: 550px;
          .video-sec {
            .btn-play {
              width: 85px;
            }
            .surf-logo {
              height: 85px;
              width: 85px;
            }
          }
        }
      }
    }
  }
  .fold9 {
    border-top-right-radius: 40px;
  }
  .lwf-banner {
    height: 120vh;
    background-size: cover;
  }
}
@media (max-width: 991px) {
  .fold5 {
    .waves-wrapper {
      background-position: 70% 0;
      min-height: calc(100vh + 700px);
      .waves-content {
        width: 100%;
        .recerate-sec {
          width: 100%;
          .recreate-head {
            font-size: 44px;
            line-height: 56px;
          }
        }
        .surf-video {
          .sub-wrap {
            width: 100%;
            .video-sec {
              .btn-play {
                width: 75px;
              }
              .surf-logo {
                height: 80px;
                width: 80px;
                right: 8px;
                top: 14%;
              }
            }
          }
        }
      }
    }
  }
  .fold8 {
    margin-top: 50px;
    padding: 0;
    .lat-rel-sec {
      .carousel-indicators {
        display: block;
        text-align: center;
        & [data-bs-target] {
          background-color: #23a2b5;
          width: 15px;
          height: 15px;
          border-radius: 50%;
        }
      }
      .carousel-inner {
        padding: 0 16px 70px;
      }
      .container {
        padding: 0px;
      }
      .carousel {
        max-width: 100%;
        margin: auto;
      }
      .carousel-caption {
        padding: 16px;
        border-radius: 20px;
        width: 100%;
        left: 0% !important;
        z-index: -1;
        padding-top: 50px;
        top: 0;
        margin: auto;
        margin-top: 155px;
        min-height: 190px;
        height: unset;
        box-shadow: 0px 1.7px 26px 0px #00000026;
        padding-bottom: 50px;
      }
      .carousel-control-next,
      .carousel-control-prev {
        display: none;
      }
      a.desktopReadMore {
        display: none;
      }
      a.mobileReadMore {
        margin-top: -46px;
        position: absolute;
        margin-left: 16px;
        display: block;
      }
      img {
        &.slider-img {
          height: 190px;
          width: 100%;
          margin-left: 0px;
          border-radius: 20px;
          object-fit: cover;
        }
      }
      .carousel-img {
        margin: auto;
        width: 95%;
        left: 0;
        right: 0;
      }
    }
  }
  .fold6 {
    margin-top: -15%;
  }
}
@media (max-width: 767px) {
  /*Modal*/
  .reg-model-popup {
    .modal-dialog {
      max-width: 100%;
      margin: 0;
      padding: 0 16px;
      .modal-form {
        padding: 20px 0px;
        a.modal-close {
          top: -51px;
          right: -16px;
          img {
            max-width: 30px;
          }
        }
        p {
          font-size: 14px;
          line-height: 22px;
        }
        .form-group {
          margin-top: 20px !important;
          &.form-group-select {
            margin-top: 15px !important;
          }
          input {
            font-size: 16px !important;
            //padding-left: 20px !important;
            padding-bottom: 5px !important;
            &::placeholder {
              font-size: 16px !important;
            }
          }
          select {
            font-size: 16px;
            padding-left: 20px;
            padding-bottom: 10px;
          }
        }
        .form-group.form-email input {
          font-size: 16px;
          &::placeholder {
            font-size: 16px;
          }
        }
        button {
          font-size: 20px;
          line-height: 30px;
          padding: 12px 24px;
        }
        .sec-modal {
          h3 {
            font-size: 32px;
            line-height: 38px;
          }
          p {
            font-size: 18px;
            line-height: 27px;
          }
        }
        .form-inline {
          button {
            height: 60px;
          }
        }
      }
    }

    .modal-content {
      border-radius: 50px 30px 50px 30px;
    }
  }
  .fold1 {
    .player-wrapper {
      video {
        height: 88vh;
      }
    }
  }
  .fold1OverlayDiv {
    .fold1ContentDiv {
      height: 88vh;
      .logoDiv,
      .letsGoDiv {
        padding-left: 16px;
        padding-right: 16px;
        .logoImage {
          width: 90px;
          height: 98px;
          object-fit: contain;
        }
      }
      .letsGoDiv {
        width: 100%;
        margin-top: 0px;
        .letsGo {
          font-size: 42px;
          line-height: 50px;
        }
        .letsGoQuestion,
        .letsGoAnswer {
          font-size: 19px;
          line-height: 28px;
          width: 100%;
        }
        .joinWaitlistButton {
          width: auto;
          height: 50px;
          font-size: 20px;
          min-width: 220px;
        }
      }
      .svgContainerDiv {
        margin-bottom: -1px;
        svg {
          max-width: calc(100% + 0px);
          height: 100%;
        }
      }
    }
  }
  // .fold2 {
  //   flex-direction: column;
  //   padding: 0 16px;
  //   padding-top: 25px;
  //   .firstColumn {
  //     .waveIcon {
  //       width: 44px;
  //       height: 30px;
  //     }
  //   }
  //   .secondColumn {
  //     margin-left: 0;
  //     .firstRow {
  //       margin-top: 25px;
  //       .theWorldsText {
  //         font-size: 22px;
  //         line-height: 26px;
  //         height: 20px;
  //       }
  //     }
  //     .secondRow {
  //       margin-top: 10px;
  //       p {
  //         font-size: 32px;
  //         line-height: 38px;
  //       }
  //     }
  //     .thirdRow {
  //       margin-top: 20px;

  //       background-position-x: right;
  //       background-position-y: top;
  //       background-repeat: no-repeat;
  //       background-size: contain;

  //       .surfAbuDhabi {
  //         font-size: 32px;
  //         line-height: 38px;
  //       }
  //       .locationIcon {
  //         height: 25px;
  //         width: 20px;
  //         object-fit: contain;
  //       }
  //       .hudayriyatIsland {
  //         font-size: 20px;
  //         line-height: 30px;
  //       }
  //       .fold2Text1 {
  //         font-size: 18px;
  //         line-height: 30px;
  //       }
  //     }
  //     .fourthRow {
  //       .readMoreBtn {
  //         font-size: 19px;
  //         line-height: 29px;
  //       }
  //       .hudayriyatIslandDiv {
  //         height: 221px;
  //         width: 100%;
  //         .hudayriyatIsland {
  //           margin-left: 0px;
  //           width: 100%;
  //           height: 100%;
  //           object-fit: cover;
  //           border-radius: 20px 45px 30px 60px;
  //         }
  //         .spectacleImage {
  //           display: none;
  //         }
  //         .ThreeStripesImageBottom,
  //         .ThreeStripesImageTop {
  //           width: 47px;
  //           height: 26px;
  //         }
  //         .ThreeStripesImageTop {
  //           top: 34px;
  //           right: -10px;
  //         }
  //         .ThreeStripesImageBottom {
  //           left: -10px;
  //           bottom: 18px;
  //         }
  //         .PinVectorImage {
  //           width: 22px;
  //           height: 47px;
  //           top: -10px;
  //           left: 35px;
  //         }
  //       }
  //     }
  //   }
  // }
  .foldSeparatorDiv {
    margin-top: 0;
  }
  .fold3 {
    /*Find Your Wave*/
    .fyw-sec {
      padding-bottom: 75px;
      padding-top: 50px;
      margin-bottom: 0px;
      // .fyw-main-img,
      // .fyw-cont {
      //   border-radius: 40px;
      // }
      .fyw-cont {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        padding: 15px 20px 30px;
        padding-top: 80px;
      }
      p {
        font-size: 17px;
        line-height: 30px;
      }
    }
    .fyw-sec h2 {
      font-size: 32px;
      margin-bottom: 30px;
    }
    .fyw-sec .tab-pane ul li {
      font-size: 16px;
    }
    .fyw-sec .tab-pane ul li span.fyw-cont-sub {
      font-size: 14px;
    }
  }
  .fold4 {
    border-top-right-radius: 40px;
    .surf-wrapper {
      padding: 110px 20px 0;
    }
    .main-wrap {
      width: auto;
    }
    .flex-box.sec-one {
      padding: 45px 0 10px;
      flex-direction: column-reverse;
    }
    .content-wrap {
      width: 260px;
      margin-bottom: 30px;
      height: 200px;
      border-radius: 45px 20px 40px 20px;
      margin: 0 auto;
    }
    .para-sec {
      width: 100%;
      margin-bottom: 50px;
      text-align: center;
      align-items: center;
      margin-top: 20px;
    }
    .flex-box {
      flex-direction: column;
      align-items: center;
    }
    .person-img {
      top: -100px;
      left: -32px;
      width: 180px;
    }
    .skelly-img {
      width: 167px;
      height: 93px;
    }
    .img-heading {
      padding-bottom: 10px;
      font-size: 20px;
      line-height: 24px;
    }
    .modon-img {
      width: 190px;
    }
    .para-sec p {
      width: 100%;
      font-size: 17px;
      line-height: 25px;
      padding: 33px 0 0;
      margin: 0 auto;
    }
    .learn-more {
      justify-content: center;
      font-size: 19px;
      line-height: 25px;
      padding-top: 15px;
    }
    .learn-arrow {
      height: 25px;
      padding-left: 10px;
    }
    .thumb-img {
      width: 38px;
      bottom: -15px;
    }
    .surf-board-img {
      width: 39px;
      bottom: -15px;
    }
    .snail-img {
      width: 30px;
      top: 0;
      left: 0;
    }
    .wave-img {
      width: 60px;
      left: -45px;
    }
    .sun-img {
      width: 85px;
      top: -35px;
      right: -40px;
    }
  }
  .fold5 {
    padding-top: 35px;
    margin-top: -80px;
    .waves-wrapper {
      min-height: calc(75vh + 200px);
      .waves-content {
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        .recerate-sec {
          width: 100%;
          .recreate-head {
            font-size: 32px;
            line-height: 38px;
          }
        }
        .surf-video {
          .sub-wrap {
            width: 100%;
            p {
              font-size: 18px;
              line-height: 28px;
            }
            .video-sec {
              .btn-play {
                width: 50px;
              }
              .surf-logo {
                height: 70px;
                width: 64px;
                top: 15%;
              }
            }
          }
        }
      }
    }
  }
  .fold6 {
    margin-top: -35%;
    .footer-sec {
      background-position: 59% top;
      .foot-form-sec {
        padding: 0 16px;
        padding-top: 170%;
        padding-bottom: 30%;
        max-width: 100%;
        margin-left: 0%;

        h2 {
          font-size: 32px;
          line-height: 42px;
        }
        .foot-form-head {
          &::before {
            margin-left: 0;
            margin-top: -25%;
            background-position: left top;
          }
        }
        .form-inline {
          max-width: 100%;

          .form-group {
            &.radio-field,
            select {
              padding-left: 20px;
            }
          }
          .radio-field {
            label {
              border-top-right-radius: 12px !important;
              border-bottom-right-radius: 12px !important;
              border-radius: 12px;
            }
            h3 {
              padding-bottom: 5px;
              font-weight: 100;
            }
          }
          button {
            padding: 13px 20px;
          }
        }
        input {
          padding-left: 20px;
          font-size: 16px !important;
          &::placeholder {
            font-size: 16px;
          }
        }
        .css-1dimb5e-singleValue {
          font-size: 16px !important;
        }
        .form-inline {
          .radio-field {
            label {
              margin-right: 10px;
              padding: 10px 15px;
              font-size: 16px;
            }
            h3 {
              font-size: 16px;
            }
          }
          .form-group {
            margin-bottom: 30px;
            select {
              font-size: 16px;
              background-position: 90% 35%;
            }
          }
        }
      }

      .foot-social {
        padding: 10px 0px 30px;
      }
      img {
        max-width: 200px;
      }
      ul {
        &.foot-menu {
          li {
            margin: 0 5px;
            a {
              font-size: 14px;
              padding-right: 10px;
            }
          }
        }
      }
      .foot-bt-sec {
        flex-direction: column-reverse;
        padding: 10px 0px;
        p {
          &.p3 {
            padding: 10px 0px 30px;
          }
        }
      }
    }
  }
  .fold7 {
    height: 160px;
    background-size: cover;
    .asFeaturedInText {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 5px;
      margin-top: -5px;
    }
    .marquee {
      img {
        width: 110px;
        margin-left: 25px;
        margin-right: 25px;
        height: 70px;
        object-fit: contain;
      }
    }
  }
  .fold8 .lat-rel-sec {
    h2 {
      font-size: 32px;
      margin-bottom: 20px;
    }
    .carousel-caption {
      h3 {
        font-size: 17px;
        line-height: 26px;
        padding-bottom: 15px;
      }
      a {
        font-size: 17px;
      }
      p {
        font-size: 17px;
        line-height: 30px;
      }
    }
  }
  .fold9 {
    padding: 0 16px;
    margin-top: -40px;
    .build-modon {
      max-width: 100%;
      margin: 70px auto 0px;
      span.modon-border {
        &::before {
          right: 0;
        }
        &::after {
          left: 0;
        }
      }
      img {
        max-width: 94px;
      }
      P {
        font-size: 17px;
        line-height: 26px;
      }
      .logo-sec {
        margin-bottom: 20px;
        img {
          max-width: 164px;
        }
        h5 {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 20px;
        }
      }
      .cont-sec {
        flex-direction: column;
        .cont-01 {
          width: 100%;
          p {
            font-size: 14px;
            line-height: 16px;
          }
        }

        .cont-02 {
          width: 100%;
          p {
            font-size: 17px;
            line-height: 28px;
            letter-spacing: 0;
          }
          a {
            font-size: 17px;
          }
        }
      }

      span.modon-border {
        margin: 30px 0px;
        &.mob-show {
          display: flex;
        }
      }
      span.modon-border {
        &.desk-show {
          display: none;
        }
      }
    }
  }
  .fold1OverlayDiv {
    .fold1ContentDiv {
      .letsGoDiv,
      .logoDiv {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
  .fold1OverlayDiv {
    .fold1ContentDiv {
      .logoDiv {
        align-items: center;
      }
    }
  }
  .lwf-banner {
    background: url(https://d2rvxcax2co8em.cloudfront.net/lwf-banner-mb.webp) no-repeat center bottom;
    background-size: contain;
    height: 75vh;
    &::before {
      background: url(https://d2rvxcax2co8em.cloudfront.net/lwf-shadow-mb.webp) no-repeat center top;
      background-size: cover;
      height: 61vh;
    }
    .lwf-cont {
      padding: 0 16px;
      h2 {
        font-size: 24px;
        line-height: 30px;
        padding: 20px 0 5px;
      }
      h3 {
        font-size: 26px;
        line-height: 34px;
        padding: 0 0 15px;
      }
      p {
        font-size: 17px;
        line-height: 30px;
      }
    }
  }

  .waveIcon {
    max-width: 45px;
  }
  .fold8 {
    margin-top: 80px;
  }

  .reg-model-popup {
    .modal-dialog {
      .modal-form .form-inline {
        .form-group.form-group-select {
          .css-13cymwt-control .css-1jqq78o-placeholder {
            font-size: 16px;
          }
          .css-t3ipsp-control .css-1jqq78o-placeholder {
            font-size: 16px;
          }
          .css-1dimb5e-singleValue {
            font-size: 16px;
          }
        }
      }
    }
  }

  .fold6 {
    .footer-sec {
      .foot-form-sec form {
        .form-group.form-group-select {
          .css-13cymwt-control .css-1jqq78o-placeholder {
            font-size: 16px;
          }
          .css-t3ipsp-control .css-1jqq78o-placeholder {
            font-size: 16px;
          }
          .css-1dimb5e-singleValue {
            font-size: 16px;
          }
          .css-13cymwt-control::before {
            top: 25px;
          }
          .css-13cymwt-control::after {
            top: 25px;
          }
          .css-1xc3v61-indicatorContainer,
          .css-15lsz6c-indicatorContainer {
            display: none;
          }
        }
      }
    }
  }
  .contactUsSuccessSwal {
    padding: 16px;
    .swal2-popup {
      width: 100%;
      padding: 100px 30px;
      border-radius: 60px 30px 60px 30px;
    }
    .swal2-title {
      font-size: 24px;
      line-height: 32px;
      padding: 0;
      margin-top: 30px;
    }
    .swal2-html-container {
      font-size: 18px;
      line-height: 27px;
      margin: 0;
      margin-top: 30px;
    }
    .swal2-image {
      margin-top: 0;
      margin-bottom: 0;
    }
    .swal2-close {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 0px;
      top: -40px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 384px) {
  .fold3 {
    .fyw-sec .tab-pane ul li {
      background-position: 0 6px;
    }
    .fyw-sec span.fyw-sub-img img {
      max-width: 80px;
    }
  }
  .fold5 {
    .waves-wrapper {
      .waves-content {
        .surf-video {
          .sub-wrap {
            .video-sec {
              .btn-play {
                width: 30px;
              }
              .surf-logo {
                height: 38px;
                width: 36px;
                right: 5px;
              }
            }
          }
        }
      }
    }
  }
  .fold5 {
    .waves-wrapper {
      min-height: calc(75vh + 350px);
    }
  }
  .reg-model-popup {
    .modal-dialog {
      .modal-form .form-inline {
        .form-group select {
          font-size: 15px;
        }
      }
    }
  }
}

/*26.10.2023*/
.fold2 {
  display: none;
}

/* Fold5(Recreating The Perfect Waves) Youtube Modal*/
.modal {
  &#youtubeModal {
    .modal-content {
      background: none;
      .btnClose {
        position: absolute;
        top: 0;
        right: -66px;
      }
    }
    .modal-body {
      padding: 0;
    }
  }
}
/*@media (max-width: 1365px) {
  .modal {
    &#youtubeModal {
      .modal-content {
        .btnClose {
          top: -66px;
          right: 0;
        }
      }
    }
  }
}*/
@media (max-width: 1199px) {
  .modal {
    &#youtubeModal {
      .modal-content {
        .btnClose {
          width: 40px;
          top: -20px;
          right: -55px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .modal {
    &#youtubeModal {
      .modal-dialog {
        max-width: none;
        margin: 75px 50px 50px;
      }
      .modal-content {
        .btnClose {
          top: -50px;
          right: 0;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .modal {
    &#youtubeModal {
      .modal-content {
        .btnClose {
          width: 30px;
          top: -40px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .modal {
    &#youtubeModal {
      .modal-dialog {
        margin: var(--bs-modal-margin);
      }
    }
  }
}
