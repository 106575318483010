@font-face {
  font-family: FertigoPro;
  src: url("./fonts/Fertigo_PRO.otf");
}

html,
body {
  height: 100%;
}
.videoDiv {
  z-index: -1;
}
.logoDiv {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}
.logoImage {
  width: 166px;
  height: 181px;
}
.player-wrapper {
  position: absolute;
}
.player-wrapper video {
  object-fit: cover;
  width: 100vw;
  min-height: 100vh;
}
.languageButton {
  height: 40px;
  border-radius: 40px;
  background: #ffffff;
  border: 2px solid #23a2b5;
  font-size: 16px;
  line-height: 16px;
  padding: 8px 20px;
}
.fold1Div {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 25px;
}
.fold2 {
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}
.firstRow {
  display: flex;
}
.waveIcon {
  width: 86px;
  height: 60px;
  object-fit: contain;
}
.secondColumn {
  margin-left: 25px;
  flex-grow: 1;
}
.theWorldsText {
  height: 60px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 55px;
  line-height: 55px;
  font-family: FertigoPro;
}
.secondRow {
  height: 70px;
  margin-top: 32px;
}
.secondRow p {
  font-size: 65px;
  line-height: 65px;
  margin: 0;
  font-family: FertigoPro;
}
.secondRow p::after {
  color: #23a2b5 !important;
}
.hudayriyatIsland {
  color: #23a2b5;
  margin-left: 11px;
  margin-bottom: 0;
  font-size: 32px;
  line-height: 32px;
}

.thirdRow {
  margin-top: 60px;
}
.surfAbuDhabi {
  font-size: 64px;
  line-height: 68px;
  color: #d09871;
}
.fold2Text1 {
  font-size: 28px;
  line-height: 42px;
  color: black;
  margin-top: 30px;
}
