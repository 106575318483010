html[dir="rtl"] {
  .player-wrapper video,
    .foldSeparatorDiv,
    .fold1OverlayDiv .svgContainerDiv svg,
    .fold2 .walkingSurfer,
    .hudayriyatIslandDiv,
    .fold4 .learn-arrow/*,
    .fold5 .waves-wrapper img*/ {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .fold1OverlayDiv {
    .languageButton {
      padding-top: 6px;
      font-size: 13px;
      line-height: 13px;
    }
  }
  .fold2 {
    z-index: 1;

    .walkingSurfer {
      padding-left: 0 !important;
      padding-right: 25px;
    }

    .hudayriyatIslandDiv {
      .hudayriyatIsland {
        transform: scaleX(-1);
        border-radius: 90px 40px 125px 60px;
      }
    }

    .secondColumn {
      margin-left: 0 !important;
      margin-right: 25px;
    }

    .readMoreBtnArrow {
      margin-left: 0 !important;
      margin-right: 2.5px;
    }
  }

  .fold3 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 200px;
    .fyw-sec .desktop-img:before {
      right: -80%;
    }
    .tab-pane {
      margin-right: 5% !important;
      margin-left: auto !important;

      &:first-child {
        margin-right: 5% !important;
        margin-left: auto !important;
      }
    }

    .fyw-sec {
      .fyw-cont {
        text-align: right;
      }

      // #nav-surf {
      //   .desktop-img {
      //     &:before {
      //       left: 19%;
      //       right: auto;
      //     }
      //   }
      // }
    }
  }

  .fold4 {
    border-top-left-radius: 200px !important;
    border-top-right-radius: 0 !important;

    .person-img {
      right: 15% !important;
      left: unset;
    }

    .wave-img {
      right: -105px;
      left: unset !important;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }

    .snail-img {
      left: 0 !important;
      right: unset;
    }

    .surf-board-img {
      right: unset !important;
      left: 12%;
    }
  }

  .foldSeparatorDiv {
    margin-top: -20px !important;
  }

  .fold5 {
    .waves-wrapper {
      background: url("https://d2rvxcax2co8em.cloudfront.net/ar-shutterstock_1168140622_1.webp");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 100% 0;

      .recreate-head {
        &:before {
          right: -10%;
        }
      }
    }
  }

  .fold8 {
    .carousel {
      margin-left: unset !important;
      margin-right: auto;
    }

    .carousel-caption {
      right: 30% !important;
      left: 0% !important;

      p,
      h3,
      a {
        text-align: right !important;

        img {
          transform: scaleX(-1);
        }
      }
    }
    a {
      text-align: right !important;

      img {
        transform: scaleX(-1);
      }
    }
    a.mobileReadMore {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  .fold5 {
    .waves-wrapper {
      .waves-content {
        .surf-video {
          .video-sec {
            .surf-logo {
              left: 10px;
              right: auto;
            }
          }
        }
      }
    }
  }

  .lwf-banner {
    background: url(https://d2rvxcax2co8em.cloudfront.net/lwf-banner-ar.webp);
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
  }

  .fold6 {
    .footer-sec {
      background: url(https://d2rvxcax2co8em.cloudfront.net/ar-footer-01.webp);
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      .foot-form-sec {
        margin-left: unset;
        margin-right: 10%;

        .foot-form-head {
          &::before {
            margin-right: -23%;
            margin-top: 45px;
            background-position: right center;
          }
        }

        form {
          .radio-field {
            label {
              margin-right: unset !important;
              margin-left: 15px;
            }
          }
        }

        p {
          text-align: right;
        }
      }

      ul {
        li {
          a {
            padding-right: 0 !important;
            padding-left: 20px;
            border-right: none;
            border-left: 1px solid #fff;
          }

          &:last-child {
            a {
              border-left: none;
            }
          }
        }
      }

      .foot-social {
        li {
          a {
            border: none !important;
            padding-left: 0 !important;
          }
        }
      }
    }
  }

  .footer-sec .foot-form-sec form .radio-field label {
    margin-right: unset !important;
    margin-left: 15px;
  }

  .fold9 {
    border-top-left-radius: 200px;
    border-top-right-radius: 0;

    .cont-02 {
      a {
        img {
          transform: scaleX(-1);
        }
      }
    }

    .build-modon {
      span.modon-border::before {
        right: 0;
      }
      span.modon-border::after {
        left: 0;
      }
    }
  }

  .reg-model-popup {
    .modal-dialog {
      .modal-form {
        p {
          text-align: right;
        }
      }
    }
  }

  .fyw-sec #nav-surf .desktop-img:before {
    background: url(https://d2rvxcax2co8em.cloudfront.net/wave-icon-01-arabic.webp) no-repeat;
    background-size: 182px;
  }
  .fyw-sec #nav-learn .desktop-img:before {
    background: url(https://d2rvxcax2co8em.cloudfront.net/wave-icon-02-arabic.webp) no-repeat;
    background-size: 182px;
  }
  .fyw-sec #nav-exp .desktop-img:before {
    background: url(https://d2rvxcax2co8em.cloudfront.net/wave-icon-03-arabic.webp) no-repeat;
    background-size: 182px;
  }
  @media (max-width: 1919px) {
    .fold3 .fyw-sec {
      .tab-content > .tab-pane:first-child {
        margin-right: 3% !important;
      }
      .desktop-img:before {
        right: -83% !important;
      }
    }
    .fyw-sec #nav-exp .desktop-img:before,
    .fyw-sec #nav-surf .desktop-img:before,
    .fyw-sec #nav-learn .desktop-img:before {
      background-size: 130px;
    }
  }

  @media (max-width: 1023px) {
    .fold3 {
      border-bottom-right-radius: 40px;

      .tab-pane {
        margin-right: 5% !important;
        margin-left: auto !important;

        &:first-child {
          margin-right: 0% !important;
          margin-left: auto !important;
        }

        #nav-surf {
          .desktop-img {
            &:before {
              left: 12%;
              right: auto;
            }
          }
        }
      }
    }

    .fold9 {
      border-top-left-radius: 40px;
    }
  }
  /* Fold5(Recreating The Perfect Waves) Youtube Modal*/
  .modal {
    &#youtubeModal {
      .modal-content {
        .btnClose {
          top: -25px;
          right: auto;
          left: -66px;
        }
      }
    }
  }

  @media (max-width: 1380px) {
    .modal {
      &#youtubeModal {
        .modal-content {
          .btnClose {
            top: -66px;
            right: auto;
            left: 0;
          }
        }
      }
    }
    .fold3 .fyw-sec {
      .desktop-img:before {
        right: -85% !important;
      }
    }
    .fyw-sec #nav-exp .desktop-img:before,
    .fyw-sec #nav-surf .desktop-img:before,
    .fyw-sec #nav-learn .desktop-img:before {
      background-size: 100px;
    }
  }

  @media (max-width: 1199px) {
    .modal {
      &#youtubeModal {
        .modal-content {
          .btnClose {
            top: -20px;
            right: auto;
            left: -55px;
          }
        }
      }
    }

    .fold6 {
      .footer-sec {
        .foot-form-sec {
          .foot-form-head {
            &::before {
              margin-right: -20%;
              margin-left: auto;
              background-position: right top;
            }
          }
        }
      }
    }

    .fyw-sec #nav-exp .desktop-img:before,
    .fyw-sec #nav-surf .desktop-img:before,
    .fyw-sec #nav-learn .desktop-img:before {
      background-size: 70px;
    }
  }

  @media (max-width: 991px) {
    .modal {
      &#youtubeModal {
        .modal-content {
          .btnClose {
            top: -50px;
            right: auto;
            left: 0;
          }
        }
      }
    }

    .fold5 {
      .waves-wrapper {
        background-position: 0 0;

        .waves-content {
          .surf-video {
            .video-sec {
              .surf-logo {
                left: 8px;
                right: auto;
              }
            }
          }
        }
      }
    }

    .fold8 {
      .carousel-caption {
        right: 0 !important;
        left: auto !important;
      }
    }
  }

  @media (max-width: 767px) {
    .fold2 {
      .secondColumn {
        margin-right: 0 !important;
      }
    }

    .lwf-banner {
      background: url(https://d2rvxcax2co8em.cloudfront.net/lwf-banner-mb-ar.webp) no-repeat center bottom;
      background-size: contain;
    }

    .fold3 {
      border-bottom-right-radius: 40px !important;

      .tab-pane {
        margin-left: 0 !important;
      }
    }

    .fold4 {
      border-top-left-radius: 40px !important;

      .person-img {
        right: -32px !important;
      }

      .sun-img {
        left: -35px !important;
        right: auto !important;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }

      .wave-img {
        right: -45px !important;
        left: auto;
      }
    }

    .fold5 {
      .waves-wrapper {
        .recreate-head::before {
          right: 0 !important;
        }
      }
    }

    .fold6 {
      .footer-sec {
        background-position: 40% top;

        .foot-form-sec {
          margin: 0 !important;
        }

        .form-group.radio-field {
          padding-left: 0 !important;
          padding-right: 20px !important;
        }

        .footer-sec {
          ul li:last-child a {
            padding-left: 0 !important;
          }
        }
      }
    }

    .fold8 {
      .carousel-caption {
        right: 0% !important;
        left: 0% !important;
      }
    }

    .modal {
      &#youtubeModal {
        .modal-content {
          .btnClose {
            top: -40px;
          }
        }
      }
    }

    .fold6 {
      .footer-sec {
        .foot-form-sec {
          .foot-form-head {
            &::before {
              margin-right: 0;
              margin-top: -25%;
              background-position: right top;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 384px) {
    .fold5 {
      .waves-wrapper {
        .waves-content {
          .surf-video {
            .video-sec {
              .surf-logo {
                left: 5px;
                right: auto;
              }
            }
          }
        }
      }
    }
  }
}
